import { FaPaintBrush, FaLaptopCode, FaBullhorn, FaMoneyBillWave, FaUserTie, FaClipboardList, FaHeadset, FaBoxOpen, FaChartLine, FaCog, FaChalkboardTeacher, FaHeartbeat, FaGavel, FaIndustry, FaSearch, FaUserShield, FaTasks, FaLock, FaPalette, FaGlobe, FaMobileAlt, FaGamepad, FaShareAlt, FaPenFancy, FaShoppingCart, FaComments, FaClipboard, FaPhoneAlt, FaBuilding, FaUsers } from 'react-icons/fa';

const categories = [
    { name: 'Design and Creative', icon: <FaPaintBrush /> },
    { name: 'Software Engineering', icon: <FaLaptopCode /> },
    { name: 'Marketing and Sales', icon: <FaBullhorn /> },
    { name: 'Finance and Accounting', icon: <FaMoneyBillWave /> },
    { name: 'Human Resources', icon: <FaUserTie /> },
    { name: 'Operations and Management', icon: <FaClipboardList /> },
    { name: 'Customer Service', icon: <FaHeadset /> },
    { name: 'Product Management', icon: <FaBoxOpen /> },
    { name: 'Data Science and Analytics', icon: <FaChartLine /> },
    { name: 'Information Technology', icon: <FaCog /> },
    { name: 'Education and Training', icon: <FaChalkboardTeacher /> },
    { name: 'Healthcare', icon: <FaHeartbeat /> },
    { name: 'Legal Services', icon: <FaGavel /> },
    { name: 'Manufacturing and Production', icon: <FaIndustry /> },
    { name: 'Research and Development', icon: <FaSearch /> },
    { name: 'Administration and Support', icon: <FaUserShield /> },
    { name: 'Project Management', icon: <FaTasks /> },
    { name: 'Cybersecurity', icon: <FaLock /> },
    { name: 'UX/UI Design', icon: <FaPalette /> },
    { name: 'Web Development', icon: <FaGlobe /> },
    { name: 'Mobile Development', icon: <FaMobileAlt /> },
    { name: 'Game Development', icon: <FaGamepad /> },
    { name: 'Social Media Management', icon: <FaShareAlt /> },
    { name: 'Content Creation', icon: <FaPenFancy /> },
    { name: 'E-commerce', icon: <FaShoppingCart /> },
    { name: 'Consulting', icon: <FaComments /> },
    { name: 'Supply Chain Management', icon: <FaClipboard /> },
    { name: 'Telecommunications', icon: <FaPhoneAlt /> },
    { name: 'Real Estate', icon: <FaBuilding /> },
    { name: 'Nonprofit and Community Services', icon: <FaUsers /> },
];

export default categories;
